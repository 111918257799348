.wrap {
    max-width: 80%;
    /*max-height: 100%;*/

    /*resize: both;*/

}
#hot {
    height: 100%;
    /*width: 100%;*/
    overflow:auto;
    resize: both;
    /*font-size: 15px;*/
}